body {
  background-color: #252a31;
  font-weight: bold;
}

body hr {
  background-color: #f8f9fa;
}

/* NavigationBar.js */
.navigation-bar {
  background-color: #003459;
  font-weight: bold;
}

.navbar-light .navbar-nav .nav-link {
  color: #f8f9fa;
}

.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .show > .nav-link {
  color: #f8f9fa;
}

.navbar-light .navbar-toggler {
  border-color: #f8f9fa;
}

.navbar-light .navbar-toggler-icon {
  background-image: url('images/hamburger.png');
}

#basic-navbar-nav .navbar-nav a:hover {
  color: #f8f9fa;
  text-decoration: underline;
}

#basic-navbar-nav .navbar-nav a.nav-item.dropdown-item:hover {
  color: black;
}

.navbar-light .navbar-nav .nav-link:focus {
  color: #f8f9fa;
}

#basic-navbar-nav a.nav-item.dropdown-item.active {
  color: black;
  background-color: transparent;
}

.contact-button {
  font-weight: bold;
}

/* Carousels.js */
.Carousel {
  margin-top: 3.6%;
  margin-bottom: 5%;
}

.carousel-caption {
  font-weight: bold;
  margin: 0;
  padding: 9px;
}

/* About.js */
.figure-caption {
  font-weight: bold;
  color: #f8f9fa;
  margin-top: 1%;
  margin-left: 10%;
  margin-right: 10%;
  text-align: left;
}

.about-header {
  color: #f8f9fa;
  margin-bottom: 1%;
}

.figure-img {
  border-radius: 5%;
}

/* Cards.js */
.card-deck {
  margin-top: 5%;
  margin-bottom: 5%;
}

.border-primary {
  border-color: #f8f9fa !important;
}

.card-text {
  text-align: left;
}

.card-procare-logo {
  margin-left: 28%;
  margin-top: 5%;
  height: 25%;
  width: 45%;
}

#section-divider {
  margin-top: 10%;
  margin-bottom: 5%;
}

/* Google Form */
.google-form {
  width: 400px;
  height: 1170px;
}

/* Affiliates.js */
.affiliate-header {
  margin-top: 5%;
  color: #f8f9fa;
}

.affiliate-container {
  display: flex;
  margin-bottom: 5%;
  color: #f8f9fa;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
}

.affiliate-col {
  padding: 5px;
}

.affiliate-col img {
  width: 143px;
  height: 143px;
}

/* Footer.js */
.footer-container {
  margin-top: 5%;
  color: #f8f9fa;
  background-color: #003459;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-content: center;
}

.footer-logo {
  margin-top: 2%;
}

.footer-content {
  display: flex;
  flex-direction: row;
  justify-content: center;
  text-align: left;
  margin-top: 2%;
  height: 100%;
  width: 50%;
}

.footer-element {
  margin-left: 2%;
  margin-right: 2%;
}

.list-unstyled a {
  margin-left: 3%;
  color: #f8f9fa;
}

.text-xs-center {
  margin-bottom: 0;
}

/* Media Queries */
@media (max-width: 600px) {
  .Carousel {
    display: none;
  }

  .mobile-view {
    display: inline;
  }

  img.d-block.w-100 {
    margin-top: 10%;
    margin-bottom: 5%;
  }

  .footer-container {
    flex-direction: column;
  }

  .footer-logo {
    margin-top: 5%;
  }

  .footer-content {
    width: 100%;
    margin-top: 5%;
    margin-bottom: 5%;
  }
}

@media (max-width: 425px) {
  .google-form {
    width: 345px;
  }
  img.d-block.w-100 {
    margin-top: 15%;
    margin-bottom: 10%;
  }
}

@media (max-width: 330px) {
  .google-form {
    width: 290px;
  }
  img.d-block.w-100 {
    margin-top: 17%;
    margin-bottom: 10%;
  }
}

@media (min-width: 601px) {
  .mobile-view {
    display: none;
    margin-top: 5%;
  }
}

@media (max-width: 600px) {
  .img.d-block.w-100 {
    margin-top: 12%;
    margin-bottom: 5%;
  }
}

@media (max-width: 991px) {
  .card-deck {
    display: block;
  }
}

@media (min-width: 576px) {
  .card-deck .card {
    margin-bottom: 5%;
  }
}

@media (max-width: 768px) {
  .carousel-caption {
    display: none;
  }
  .about-header {
    font-size: 1.17em;
    font-weight: bolder;
    margin-bottom: 3%;
  }
  .affiliate-header {
    font-size: 1.17em;
    font-weight: bolder;
    margin-bottom: 3%;
  }
}
